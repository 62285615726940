<script setup>
import { baseYearRange } from "@/assets/baseTypes.js";

detectScreenSize();
const store = useBaseDataStore();
function onDonorSelected(donor) {
  if (donor.iso) navigateTo(`/bydonors/${donor?.iso}`);
}
function onRecipientSelected(recipient) {
  if (recipient._code) navigateTo(`/recipients/${recipient?._code}`);
}
</script>

<template>
  <div class="home-page-container bg-Primary-600 px-6">
    <div class="container max-w-screen-lg mx-auto pt-8 pb-24">
      <div class="mt-12">
        <!-- include links to the other pages-->
        <h1 class="text-center text-4xl font-bold leading-tight text-white  max-w-screen-sm mx-auto">
          Where Does European Democracy Support Funding Go? 
        </h1>
        <p class="text-center mx-auto text-white mt-10 text-2xl leading-7">
          Explore funding data on who gives and receives European democracy
          support, with transparency on both donors and recipients, as well as
          categorised spending areas.
        </p>
      </div>
    </div>
  </div>


  <div class="bg-Primary-800 py-10 px-14 text-white">
    <div class="container max-w-screen-lg">
      <hr class="mt-10" />
      <h2 class="pt-3 uppercase text-center text-white font-normal text-sm">
        Start Your Journey
      </h2>
      <div class="mt-10 flex gap-5">
        <InterfaceBlurb
          heading="Spending Flows"
          desc="Navigate a rich data overview tracking funding flows between donors and recipients, filtered by topic."
          image="/flows.png"
          href="/flow"
        />
        <InterfaceBlurb
          heading="Spending Map"
          desc="Explore a map showing where democracy spending from European donors is disitrubted across the world."
          image="/map.png"
          href="/geo"
        />
      </div>
      <div class="divider mt-10 mb-5 h-[1px] w-full bg-Neutral-400" />
      <h2 class="mt-5 uppercase text-center text-white font-normal text-sm">
        Or Select What You're Looking For
      </h2>
      <div class="mt-5 flex gap-5">
        <InterfaceComboBox
          :allAvailableOptions="store.allDonorNames"
          @update:model-value="($event) => onDonorSelected($event)"
          heading="Find a donor"
          caption="Select a donor"
          large
        ></InterfaceComboBox>
        <InterfaceComboBox
          :allAvailableOptions="store.allRecipientNames"
          @update:model-value="($event) => onRecipientSelected($event)"
          heading="Find a recipient"
          caption="Select a recipient"
          large
        ></InterfaceComboBox>
      </div>
    </div>
  </div>

  <div class="bg-Primary-50  px-14 text-black ">
    <div class="container max-w-screen-lg mx-auto py-14">
      <p class="text-base leading-7">
        Our online data tool visualises democracy support spending from European
        institutions and European donors for the period
        {{ baseYearRange[0] }}-{{ baseYearRange[1] }}. This tool uses data
        recategorised by the European Democracy Hub team, focusing on 12 key
        core democracy support areas: Civil and Political Rights, Civil Society
        Support, Civic and Political Education, Electoral Assistance, Election
        Observation & Follow-up Missions, Justice and the Rule of Law, Media
        Support, Parliamentary Strengthening, Political Participation, Political
        Party Support, Political Inclusion, Transparency & anti-corruption. All
        figures are displayed in USD millions.
      </p>
    </div>
  </div>
  
  <InterfaceSourcesText></InterfaceSourcesText>
  <!--div>
    <div class="container max-w-screen-xl max-auto py-16 leading-7 text-sm font-semibold">
      <p>
        The European Democracy Hub continues the work set forth by Carnegie Europe and the European Partnership for Democracy. 
      </p>
      <p>
        An extended archive of research, as well as the latest edition of the European Democracy Support Annual Review, can be accessed by clicking here.
      </p>
    </div>
  </div-->
</template>

<style scoped>
li {
  @apply my-2;
}
.home-page-container {
  background: linear-gradient(0deg, rgba(38, 46, 106, 0.70) 0%, rgba(38, 46, 106, 0.70) 100%), url("~/assets/img/edh_bg.jpg"), lightgray 20% / contain repeat;
  background-position-y: top;
  background-position-x: center;  
}
</style>
